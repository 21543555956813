import { Address } from 'components/form-fields/address-section/types'
import { Region } from 'types/region'
import { Product } from 'types/product-data'

export type StartSimulationFormProps = {
  allowedCompanyTypes?: [CompanyType, ...CompanyType[]]
  className?: string
  noOverlay?: boolean
  specificProduct?: Product
  withAvailability?: boolean
  wrapperClassName?: string
}

export type StartSimulationFormInputs = {
  companyType: CompanyType
  fuelType: FuelType
  postalCode: Address['postalCode']
  region: Region
  townCode: Address['townCode']
  townName: Address['townName']
}

export enum CompanyType {
  COMPANY = 'company',
  RESIDENTIAL = 'residential'
}

export enum FuelType {
  ELECTRICITY = 'electricity',
  ELECTRICITY_GAS = 'electricity-gas'
}
